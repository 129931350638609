<template>
    <div slot="body" class="container offset-bottom">
        <iframe id="contentIframe" class="custom-scrollbar" :src="flowSrc" style=" height:100%;width:100%;margin:0;border:0; border-left: 1px solid rgb(238, 234, 234); border-right: 1px solid rgb(238, 234, 234);"> </iframe>
    </div>
</template>


<script>
export default {


    data() {
        return {
            flowSrc: null,
        }
    },
  created() {
      var host = window.location.protocol+"//"+window.location.host;
      // const host = location.hostname; // 获取当前页面的主机名
      console.log(host);
      this.flowSrc = host;  //直接给flowSrc赋值链接
    },
    mounted() {
      this.initIframe()
      window.onresize = () => {
        this.initIframe()
      }
      this.checkRedirect();
    },
    methods: {
      initIframe() {
          $("#contentIframe").css("height", ($(window).height() - 5)  + "px");
          $("#contentIframe").css("border-left", "1px solid rgb(238 234 234)");
          $("#contentIframe").css("border-right", "1px solid rgb(238 234 234)");
          $(window).resize(function() {//当浏览器大小变化时
            $("#contentIframe").css("height", ($(window).height() - 5)  + "px");
          });
      },
      checkRedirect() {
        const width = window.innerWidth;
        if (width <= 768) {
          this.$router.push('/');
        }
      }
    }


}
</script>
<style lang="less" scoped>
 #app{
   height: 100%;
 }
.container{
  width: 500px;
    margin: 0 auto;
    height: 100vh;
}
</style>
